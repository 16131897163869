import { useEffect } from "react"
import Helmet from "react-helmet"
import Seo from "components/seo"
import Layout from "components/layout"
import { useDispatch } from "react-redux"
import { F_Q_CATALOGS, F_Q_BANNERS } from "lib/function"
import HomeSale from "components/pages/home/home-sale"
import HomeSlider from "components/pages/home/home-slider"
import HomeFeatured from "components/pages/home/home-featured"
import HomeProjectGallery from "components/pages/home/home-project-gallery"
import HomeConnectUs from "components/pages/home/home-connect-us"
import HomeRecent from "@components/pages/home/home-recent"
import { SET_BACKGROUND_TYPE } from "lib/redux/types"

export default function HomePage({ ...props }) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (props?.hero?.banners?.[0]) {
      let { background_type } = props?.hero?.banners[0]
      if (background_type)
        dispatch({ type: SET_BACKGROUND_TYPE, payload: background_type })
    }
  }, [])
  return (
    <>
      <Seo title="Home" />
      <Helmet>
        <body className="bd-home" />
      </Helmet>
      <Layout>
        <HomeSlider {...props} />
        <HomeFeatured {...props} />
        <HomeSale {...props} />
        <HomeProjectGallery {...props} />
        <HomeRecent locale={props.locale} wishlists={props.wishlists} />
        <HomeConnectUs locale={props.locale} />
      </Layout>
    </>
  )
}

export const getStaticProps = async (ctx) => {
  let token = process.env.INIT_TOKEN
  let { locale } = ctx
  let isFeatured = await F_Q_CATALOGS(
    { lang: locale, is_featured: 1, limit: 25, sort: "order_weight-asc" },
    token
  )
  let gallery = await F_Q_BANNERS(
    { lang: locale, type: "project_gallery" },
    token
  )
  let hero = await F_Q_BANNERS({ lang: "id", type: "hero_banner" }, token)
  let newCatalogs = await F_Q_CATALOGS(
    { lang: locale, is_new: 1, limit: 25, sort: "order_weight-asc" },
    token
  )
  return {
    props: {
      isFeatured: isFeatured.data ?? {},
      gallery: gallery.data ?? {},
      locale,
      hero: hero.data ?? {},
      newCatalogs: newCatalogs.data ?? {},
      token,
    },
    revalidate: 60,
  }
}

// export const getStaticProps = async (ctx) => {
//   const client = staticApollo(`${process.env.INIT_TOKEN}`)
//   let { locale } = ctx

//   const isFeatured = await client.query({
//     query: Q_CATALOGS,
//     variables: { lang: locale, is_featured: 1, limit: 25 },
//     fetchPolicy: "no-cache",
//     errorPolicy: "all",
//   })

//   const gallery = await client.query({
//     query: Q_BANNERS,
//     variables: { lang: locale, type: "project_gallery" },
//     fetchPolicy: "no-cache",
//     errorPolicy: "all",
//   })
//   return { props: {}, revalidate: 60 }
// }

// export const getServerSideProps = async (ctx) => {
// let { token } = await TokenValidation(ctx)
// let { locale } = ctx
// let isFeatured = await F_Q_CATALOGS(
//   { lang: locale, is_featured: 1, limit: 25 },
//   token
// )
// let gallery = await F_Q_BANNERS(
//   { lang: locale, type: "project_gallery" },
//   token
// )
// let hero = await F_Q_BANNERS({ lang: "id", type: "hero_banner" }, token)
// let newCatalogs = await F_Q_CATALOGS(
//   { lang: locale, is_new: 1, limit: 25 },
//   token
// )
// if (isFeatured?.data && gallery?.data && hero?.data && newCatalogs?.data) {
//   return {
//     props: {
//       isFeatured: isFeatured.data,
//       gallery: gallery.data,
//       locale,
//       hero: hero.data,
//       newCatalogs: newCatalogs.data,
//       token,
//     },
//   }
// } else {
//   return { notFound: true }
// }
// }
